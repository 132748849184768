<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>
    <div :hidden="hidden">
      <div class="mb-2 d-flex justify-content-end">
        <b-button
          v-if="$hasFonction(user, 'Ajouter association')"
          align-h="between"
          variant="primary"
          @click="createAssociation()"
        >
          Ajouter une association
        </b-button>
      </div>
      <b-card no-body title="" class="mt-1">
        <div class="my-2 mx-1 row">
          <!-- search start -->
          <div class="col-4">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Rechercher"
            >
            </b-form-input>
          </div>

          <div class="col-3"></div>
          <!-- search end -->

          <!-- bouton export start -->
          <b-button
            class="col-2"
            v-if="$hasFonction(user, 'Exporter association')"
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>
          <!-- bouton export end -->

                <!-- bouton export start -->
          <b-button
            class="ml-2 mt-sm-0"
            v-if="$hasFonction(user, 'Exporter association')"
            variant="outline-primary"
            size="sm"
            @click="ExportAllToFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>
          <!-- bouton export end -->

        </div>

        <!-- table start -->
        <b-table
          v-model="ExcelAssociations"
          id="my-table"
          responsive="sm"
          :fields="headers"
          :items="associations"
          hover
          striped
          @row-clicked="onRowClicked"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
          show-empty
          class="border-bottom"
        >
          <template #cell(created_at)="data">
            {{
              data.item.dernier_inventaire
                ? data.item.dernier_inventaire
                : data.item.created_at
            }}
          </template>
          <template #cell(montant)="data">
            <div>
              {{ $thousandSeparator(data.item.montant) }}
            </div>
          </template>

          <template #cell(actions)="data">
            <div v-if="data.item.etape == 6">
              <b-button
                :id="'button-1' + data.item.id"
                size="sm"
                variant="flat-primary"
                @click="showAssociation(data.item)"
              >
              </b-button>
              <!-- <b-tooltip
                :target="'button-1' + data.item.id"
                title="Détails"
                variant="dark"
              ></b-tooltip> -->

              <!-- <b-button
                :id="'button-2' + data.item.id"
                size="sm"
                variant="flat-primary"
                class="btn-icon"
                @click="editAssociation(data.item)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-tooltip
                :target="'button-2' + data.item.id"
                title="Editer"
                variant="dark"
              ></b-tooltip> -->
            </div>

            <div v-else>
              <b-button
                :id="'button-3' + data.item.id"
                size="sm"
                variant="flat-warning"
                @click="resume(data.item)"
              >
                <feather-icon icon="TrendingUpIcon" />
              </b-button>
              <b-tooltip
                :target="'button-3' + data.item.id"
                title="Poursuivre"
                variant="dark"
              ></b-tooltip>

              <b-button
                v-if="$hasFonction(user, 'Supprimer association')"
                :id="'button-4' + data.item.id"
                size="sm"
                variant="flat-danger"
                @click="deleteAssociation(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-tooltip
                :target="'button-4' + data.item.id"
                title="Supprimer"
                variant="dark"
              ></b-tooltip>
            </div>
          </template>
          <!-- iconAction end -->

          <template #empty>
            <div class="text-center">
              <span class="text-nowrap">Aucune donnée existante</span>
            </div>
          </template>

          <template #emptyfiltered>
            <div class="text-center">
              <span class="text-nowrap"
                >L'élément recherché n'est pas dans la liste</span
              >
            </div>
          </template>
        </b-table>
        <!-- table end -->

        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-1" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}</span>
                <span class="text-nowrap ml-1" v-if="allRows == 0"> 0 éléments
            </span>
          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
      </b-card>
    </div>

    <div :hidden="hiddenEmpty" class="text-center">
      <empty-list></empty-list>
      <b-button variant="primary" size="sm" @click="etatAdd">
        <b-icon-plus></b-icon-plus>
        AJOUTER
      </b-button>
    </div>

    <!-- modal edit start  -->
    <b-modal
      id="modal-edit"
      v-model="modalEdit"
      :ok-disabled="isOperating"
      title="Modifier les informations"
      @hidden="resetModal"
      hide-footer
      centered
    >
      <!-- form start -->
      <b-form ref="editForm" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <label for="name">Nom et Prenom</label>
            <b-form-input
              id="name"
              placeholder="Nom et Prenom"
              type="text"
              v-model="editedForm.name"
              :state="errors.length > 0 ? false : null"
              @click="resetInputNameModal"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsNameModal
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required"
          >
            <label for="code">Code</label>
            <b-form-input
              id="code"
              v-model="editedForm.code"
              placeholder="code"
              :state="errors.length > 0 ? false : null"
              @click="resetInputCodeModal"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsCodeModal
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
            variant="outline-secondary"
            type="reset"
            class="mt-2 mr-1"
            @click="modalEdit = false"
          >
            Annuler
          </b-button>

          <b-button
            v-if="etatButtonEditAssociation"
            variant="primary"
            class="mt-2"
            @click="handleOk"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
              animation="spin"
              font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
      <!-- form end -->
    </b-modal>
    <!-- modal edit end-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPlus,
  BTooltip,
  BIconPencilFill,
  BIconTrashFill,
  BIconFileEarmarkArrowDownFill,
  BIconCircleFill,
  BIconArrowClockwise,
  BProgress,
  BProgressBar,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    BTooltip,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
    BIconCircleFill,
    BProgress,
    BProgressBar,
    BIconArrowClockwise,
  },
  data() {
    return {
      etatButtonEditAssociation: true,
      maxValueProgressBar: 6,
      empty: true,
      isOperating: false,
      required,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      errorsName: null,
      errorsCode: null,
      errorsMontant: null,
      errorsNameModal: null,
      errorsCodeModal: null,
      allRows: 0,
      modalEdit: false,
      editedForm: {
        name: "",
        code: "",
      },
      // totalRows: 1,
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [],
      headers: [
        {
          key: "name",
          label: "Nom",
          sortable: true,
          class: "w-45",
        },
        {
          key: "code",
          label: "Code",
          sortable: true,
          class: "w-5",
        },
        {
          key: "montant",
          label: "Capital",
          sortable: true,
          class: "w-25",
        },
        {
          key: "created_at",
          label: "Dernière modification",
          sortable: true,
          class: "w-25",
        },
       /* {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: "width: 200px",
        },*/
      ],
      associations: [],
      ExcelAssociations: null,
      editedForm: {},
      defaultForm: {},
      items: [
        {
          selected: "male",
          selected1: "designer",
          prevHeight: 0,
        },
      ],
      ci: "",
      frais: "",
      chargeurs: [],
      listeChargeurs: [],
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      // Set the initial number of items
      this.pageOptions = [5, 10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
  },
  created() {
    this.getAssociations();
  },
  methods: {
    resume(item) {
      this.$router.push({
        name: "associations-resume",
        params: { id: item.id },
      });
    },
    etatAdd() {
      this.empty = false;
      this.createAssociation();
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    resetModal() {
      this.editedForm.name = "";
      this.editedForm.code = "";
      this.resetInputModal();
    },
    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },
    resetInputCodeModal() {
      if (this.errorsCodeModal) {
        this.errorsCodeModal = null;
      }
    },
    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputCodeModal();
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getAssociations() {
      this.$http
        .get("/associations")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }
          this.associations = res.data.data.reverse();
          if (this.associations.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          this.allRows = this.associations.length
        })
        .catch((e) => {
          console.log(e);
        });
    },
    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.isOperating = true;

      this.etatButtonEditAssociation = false;

      this.$http
        .put("/associations/" + this.editedForm.id, this.editedForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEditAssociation = true;
            this.resetInputModal();
            this.editedForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEdit = false;
            this.showToast("Association modifié avec succès", "success");
            this.getAssociations();
          } else {
            this.etatButtonEditAssociation = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditAssociation = true;
          this.isOperating = false;
          var err = e.response.data.errors;
          if (err.name) {
            this.errorsNameModal = err.name[0];
          }
          if (err.code) {
            this.errorsCodeModal = err.code[0];
          }
        });
      // 	}
      // });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },
    editAssociation(item) {
      this.editedForm = Object.assign({}, item);
      this.modalEdit = true;
    },
    deleteAssociation(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des Associations ?",
          {
            title: "Supprimer un association",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/associations/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getAssociations();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },
    showAssociation(item) {
      window.sessionStorage.setItem("association_id", item.id);
      localStorage.setItem("association_name", item.name);
      this.$router.push({
        name: "associations-show",
        params: {
          id: item.id,
          association_id: item.id,
          association_name: item.name,
          dernierInventaire: item.dernier_inventaire,
        },
      });
    },
    onRowClicked(item, index, event) {
      window.sessionStorage.setItem("association_id", item.id);
      localStorage.setItem("association_name", item.name);
      this.$router.push({
        name: "associations-show",
        params: {
          id: item.id,
          association_id: item.id,
          association_name: item.name,
          dernierInventaire: item.dernier_inventaire,
        },
      });
    },
    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      this.ExcelAssociations.forEach((element) => {
        let obj = {
          NOM: element.name,
          CODE: element.code,
          CAPITAL: element.montant,
          "DATE ENRISTREMENT": this.$formatDate(element.created_at),
        };
        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste Associations",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      this.ExcelAssociations.forEach((element) => {
        let obj = {
          NOM: element.name,
          CODE: element.code,
          CAPITAL: element.montant,
          "DATE ENRISTREMENT": this.$formatDate(element.created_at),
        };
        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste Associations",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    createAssociation() {
      this.$router.push({ name: "associations-create" });
    },
  },
};
</script>
<style>
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}
</style>
